import { useEffect } from "react"
import { navigate } from "gatsby"

const NotFound = ({location}) => {
	useEffect(() => {
		if (location.pathname.length > 1) {
			const srcpath = location.pathname.substring(0,1)==="/"?location.pathname.substring(1):location.pathname;
			const pathlist = srcpath.split("/");
			if (pathlist[0] === "reports") {
				navigate("/reports");
				return;
			}
		}
		navigate("/")
	}, [location])
	return null
}

export default NotFound;
